export default [
  {
    path: 'operations-center',
    name: 'Facebook Operations Center',
    component: () => import('@/views/operations/OperationsCenterFacebook.vue'),
    meta: { requiresAcl: ['PAGE_OPS_FACEBOOK_REPORT'] },
  },
  {
    path: 'page-report',
    name: 'Facebook Page Report',
    component: () =>
      import('@/views/operations/facebook-page-report/PageReport.vue'),
    meta: { requiresAcl: ['PAGE_OPS_FACEBOOK_PAGE_REPORT'] },
  },
]
