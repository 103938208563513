import { h, resolveComponent } from 'vue'

import youtubeRoutes from './youtube-routes'
import facebookRoutes from './facebook-routes'
import instagramRoutes from './instagram-routes'
import tiktokRoutes from './tiktok-routes'

export default [
  {
    path: 'youtube',
    name: 'Youtube',
    redirect: '/youtube/operations-center',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [...youtubeRoutes],
  },
  {
    path: 'facebook',
    name: 'Facebook',
    redirect: '/facebook/operations-center',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [...facebookRoutes],
  },
  {
    path: 'instagram',
    name: 'Instagram',
    redirect: '/instagram/operations-center',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [...instagramRoutes],
  },
  {
    path: 'tiktok',
    name: 'Tiktok',
    redirect: '/tiktok/operations-center',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [...tiktokRoutes],
  },
  {
    path: 'deals',
    name: 'Deals',
    component: () => import('@/views/operations/deals/Deals.vue'),
    meta: { requiresAcl: ['PAGE_OPS_CLIENT_DEALS'] },
  },
  {
    path: 'post-deal-report',
    name: 'Post Deal Report',
    component: () =>
      import('@/views/operations/post-deal-report/PostDealReport.vue'),
    meta: { requiresAcl: ['PAGE_OPS_CLIENT_DEALS_REPORT'] },
  },
  {
    path: 'comparative',
    name: 'Comparative',
    component: () =>
      import('@/views/operations/comparative-report/ComparativeAnalysis.vue'),
    meta: { requiresAcl: ['PAGE_OPS_COMPARATIVE_REPORT'] },
  },
  {
    path: 'chrome-extension-youtube',
    name: 'Operations Center Chrome Extension Youtube',
    component: () =>
      import('@/views/operations/OperationsCenterChromeExtensionYoutube.vue'),
    meta: { requiresAcl: ['PAGE_OPS_CHROME_EXTENSION'] },
  },
  {
    path: 'funding-applications',
    name: 'Funding Applications',
    component: () =>
      import('@/views/operations/funding-applications/FundingApplications.vue'),
    meta: { requiresAcl: ['PAGE_FUNDING_APPLICATIONS'] },
  },
]
