export default [
  {
    path: 'operations-center',
    name: 'Youtube Operations Center',
    component: () => import('@/views/operations/OperationsCenterYoutube.vue'),
    meta: { requiresAcl: ['PAGE_OPS_YOUTUBE_REPORT'] },
  },
  {
    path: 'channel-report',
    name: 'Youtube Channel Report',
    component: () =>
      import('@/views/operations/youtube-channel-report/ChannelReport.vue'),
    meta: { requiresAcl: ['PAGE_OPS_YOUTUBE_CHANNEL_REPORT'] },
  },
]
