import { h, resolveComponent } from 'vue'

export default [
  {
    path: 'home',
    name: 'Creator Home',
    component: () => import('@/views/creator/Home.vue'),
    meta: { requiresAcl: ['PAGE_CREATOR_HOMEPAGE'] },
  },
  {
    path: 'youtube',
    name: 'Creator Youtube',
    redirect: '/youtube/dashboard',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: 'dashboard',
        name: 'Creator Youtube Dashboard',
        component: () => import('@/views/creator/youtube/Dashboard.vue'),
        meta: { requiresAcl: ['PAGE_CREATOR_YOUTUBE_DASHBOARD'] },
      },
    ],
  },
  {
    path: 'facebook',
    name: 'Creator Facebook',
    redirect: '/facebook/dashboard',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: 'dashboard',
        name: 'Creator Facebook Dashboard',
        component: () => import('@/views/creator/facebook/Dashboard.vue'),
        meta: { requiresAcl: ['PAGE_CREATOR_FACEBOOK_DASHBOARD'] },
      },
    ],
  },
]
