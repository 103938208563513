export default [
  // we are keeping old urls for mailings etc.
  // redirections start
  {
    path: '/operations-center-youtube',
    redirect: { path: 'operations/youtube/operations-center' },
  },
  {
    path: '/operations-center',
    redirect: { path: 'operations/youtube/operations-center' },
  },
  {
    path: '/channel-report',
    redirect: { path: 'operations/youtube/channel-report' },
  },
  {
    path: '/operations-center-facebook',
    redirect: { path: 'operations/facebook/operations-center' },
  },
  {
    path: '/page-report',
    redirect: { path: 'operations/facebook/page-report' },
  },
  {
    path: '/operations-center-instagram',
    redirect: { path: 'operations/instagram/operations-center' },
  },
  {
    path: '/account-report',
    redirect: { path: 'operations/instagram/account-report' },
  },
  {
    path: '/creator/dashboard',
    redirect: { path: 'creators/home' },
  },
  {
    path: '/creators/dashboard',
    redirect: { path: 'creators/home' },
  },
  {
    path: '/creator-dashboard-homepage',
    redirect: { path: 'creators/home' },
  },
  {
    path: '/creator-youtube-dashboard',
    redirect: { path: 'creators/youtube/dashboard' },
  },
  {
    path: '/creator-dashboard',
    redirect: { path: 'creators/home' },
  },
  {
    path: '/creator-youtube-dashboard',
    redirect: { path: '/creators/youtube-dashboard' },
  },
  {
    path: '/creator-dashboard-homepage',
    redirect: { path: '/creators/homepage' },
  },
  {
    path: '/deals',
    redirect: { path: '/operations/deals' },
  },
  {
    path: '/post-deal-report',
    redirect: { path: '/operations/post-deal-report' },
  },
  {
    path: '/comparative',
    redirect: { path: '/operations/comparative' },
  },
  {
    path: '/chrome-extension-youtube',
    redirect: { path: '/operations/chrome-extension-youtube' },
  },
  {
    path: '/funding-applications',
    redirect: { path: '/operations/funding-applications' },
  },
  // redirections end
]
