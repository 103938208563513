const state = {
  channelName: null,
  channelLink: null,
  mostRecentDataExtracted: null,
  mostRecentDataExtractedMTD: null,
  refreshMTDData: null,
  channelCreationDate: null,
  channelAge: null,
  category: null,
  totalSubscribers: null,
  totalVideos: null,
  totalLifetimeViews: null,
  loading: null,
  catalogLicensingExists: null,
  catalogLicensedPeriods: null,
  catalogLicensedCheckDate: null,
  reportGenerationTime: null,
  dataRefreshDay: null,
  dataRefreshPriority: null,
  pairingActive: null,
  mostRecentDataExtractedChannel: null,
  reportGenerationTimeChannel: null,
  regenerateReport: null,
  refreshData: null,
}

const mutations = {
  INVALIDATE_CHANNEL_STATE(state) {
    state.channelName = null
    state.channelLink = null
  },
  SET_CHANNEL(state, payload) {
    Object.keys(payload).forEach((key) => {
      state[key] = payload[key]
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
