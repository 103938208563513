import { cloneDeep } from 'lodash'
import abbr from '@/helpers/abbreviate'
import i18n from '@/plugins/i18n'
let $i18n = i18n.global

const getValueAsNumber = function (value) {
  if (typeof value === 'string' && value !== '') {
    return Number(value)
  }
  return value
}

const numberFormatter = function (obj, returnAsInteger) {
  let value = getValueAsNumber(obj.value)

  if (returnAsInteger) {
    return value
  }

  if (obj.abbr) {
    return abbr(
      obj.value,
      obj.abbrCount,
      obj.decimalPoint,
      obj.forcedAbbrLetter,
      obj.hideAbbrLetter,
      obj.thousandSeparator,
    )
  }

  if (obj.thousandSeparator) {
    return $i18n.n(value, 'decimal', {
      minimumFractionDigits: obj.decimalPoint || 0,
      maximumFractionDigits: obj.decimalPoint || 0,
    })
  }
  // if you want to hide unnecessary zero decimals, check: https://stackoverflow.com/questions/17555999/tofixed-not-for-0
  return value.toFixed(obj.decimalPoint)
}

const doubleFormatter = function (obj, returnAsInteger) {
  let value = getValueAsNumber(obj.value)

  if (returnAsInteger) {
    return value
  }

  if (obj.abbr) {
    return abbr(
      obj.value,
      obj.abbrCount,
      obj.decimalPoint,
      obj.forcedAbbrLetter,
      obj.hideAbbrLetter,
      obj.thousandSeparator,
    )
  }

  return $i18n.n(value, 'decimal', {
    minimumFractionDigits: obj.decimalPoint,
    maximumFractionDigits: obj.decimalPoint,
  })
}

const percentageFormatter = function (obj, returnAsInteger) {
  let value = getValueAsNumber(obj.value)

  let _value = value * 100

  if (returnAsInteger) {
    return _value
  }

  if (obj.abbr) {
    return (
      abbr(
        obj.value,
        obj.abbrCount,
        obj.decimalPoint,
        obj.forcedAbbrLetter,
        obj.hideAbbrLetter,
        obj.thousandSeparator,
      ) + '%'
    )
  }

  if (obj.thousandSeparator) {
    return (
      $i18n.n(_value, 'decimal', {
        minimumFractionDigits: obj.decimalPoint,
        maximumFractionDigits: obj.decimalPoint,
      }) + '%'
    )
  }
  return _value.toFixed(obj.decimalPoint) + '%'
}

const currencyFormatter = function (obj, returnAsInteger) {
  let value = getValueAsNumber(obj.value)

  if (returnAsInteger) {
    return value
  }

  let currencySign = obj.currencySign || ''

  if (obj.abbr) {
    return (
      currencySign +
      abbr(
        obj.value,
        obj.abbrCount,
        obj.decimalPoint,
        obj.forcedAbbrLetter,
        obj.hideAbbrLetter,
        obj.thousandSeparator,
      )
    )
  }

  if (obj.thousandSeparator) {
    return (
      currencySign +
      $i18n.n(value, 'decimal', {
        minimumFractionDigits: obj.decimalPoint || 0,
        maximumFractionDigits: obj.decimalPoint || 0,
      })
    )
  }

  return currencySign + value.toFixed(obj.decimalPoint)
}

const percentageCurrencyFormatter = function (obj, returnAsInteger) {
  let value = getValueAsNumber(obj.value)

  // let _value = value * 100
  let _value = value

  if (returnAsInteger) {
    return _value
  }

  let currencySign = obj.currencySign || ''

  if (obj.abbr) {
    return (
      currencySign +
      abbr(
        obj.value,
        obj.abbrCount,
        obj.decimalPoint,
        obj.forcedAbbrLetter,
        obj.hideAbbrLetter,
        obj.thousandSeparator,
      )
    )
  }

  if (obj.thousandSeparator) {
    return (
      currencySign +
      $i18n.n(_value, 'decimal', {
        minimumFractionDigits: obj.decimalPoint || 0,
        maximumFractionDigits: obj.decimalPoint || 0,
      })
    )
  }

  return currencySign + _value.toFixed(obj.decimalPoint)
}

const dateFormatter = function (obj) {
  return $i18n.d(obj.value, 'short')
}

const monthFormatter = function (obj) {
  return $i18n.d(obj.value, 'monthly')
}

/**
 *
 * @param {Object} _valueObject the object that coming from directly backend
 * @param {*} returnAsInteger
 * @param {*} nullable
 * @param {*} unAbbr override attr: true from the value object to get unabbreviated value. (ie: get 1.000.000 instead of 1M)
 * @param {*} falseable
 * @returns
 */
const mmFormatter = function (
  valueObject,
  returnAsInteger,
  nullable,
  unAbbr,
  falseable,
) {
  if ((nullable && valueObject?.value === null) || (nullable && !valueObject)) {
    return null
  }

  if (falseable && (valueObject?.value === false || !valueObject)) {
    return valueObject?.value || valueObject
  }

  if (
    typeof valueObject === 'undefined' ||
    !valueObject ||
    valueObject?.value === null
  ) {
    return ''
  }

  const _valueObject = cloneDeep(valueObject) // clone object to prevent mutations

  if (!_valueObject) {
    return ''
  }

  if (_valueObject.value === null) {
    return ''
  }

  if (unAbbr) {
    _valueObject.abbr = false
  }

  switch (_valueObject.dataType) {
    case 'STRING':
      return _valueObject.value
    case 'NUMBER':
      return numberFormatter(_valueObject, returnAsInteger)
    case 'DOUBLE':
      return doubleFormatter(_valueObject, returnAsInteger)
    case 'PERCENTAGE':
      return percentageFormatter(_valueObject, returnAsInteger)
    case 'CURRENCY':
      return currencyFormatter(_valueObject, returnAsInteger)
    case 'PERCENTAGE_CURRENCY':
      return percentageCurrencyFormatter(_valueObject, returnAsInteger)
    case 'DATE':
      return dateFormatter(_valueObject)
    case 'MONTH':
      return monthFormatter(_valueObject)
    default:
      return _valueObject.value ?? _valueObject
  }
}

export default mmFormatter
