import UnauthLayout from '@/layouts/UnauthLayout'

export default [
  {
    path: '404',
    name: 'Page404',
    component: () => import('@/views/pages/Page404.vue'),
  },
  {
    path: '500',
    name: 'Page500',
    component: () => import('@/views/pages/Page500.vue'),
  },
  {
    path: 'default-route-error',
    name: 'Default Route Error',
    component: () => import('@/views/pages/DefaultRouteError.vue'),
  },
  {
    path: 'user-exists',
    name: 'User Already Exists!',
    component: () => import('@/views/pages/PageUserExists.vue'),
  },
  {
    path: 'login',
    name: 'Login',
    component: () => import('@/views/pages/authorization/Login.vue'),
  },
  {
    path: 'register',
    name: 'Register',
    component: () => import('@/views/pages/authorization/Register.vue'),
  },
  {
    path: 'forgot-password',
    name: 'Forgot Password',
    component: () => import('@/views/pages/authorization/ForgotPassword.vue'),
  },
  {
    path: 'update-password',
    name: 'Update Password',
    component: () => import('@/views/pages/authorization/UpdatePassword.vue'),
  },
  {
    path: 'change-first-password',
    name: 'Change First Password',
    component: () =>
      import('@/views/pages/authorization/ChangeFirstPassword.vue'),
  },
  {
    path: 'confirm-email',
    name: 'Confirm Email',
    component: () => import('@/views/pages/authorization/ConfirmEmail.vue'),
  },
  {
    path: 'post-pair-error',
    name: 'Post Pair Error',
    component: () => import('@/views/pages/PostPairError.vue'),
  },
  {
    path: '/unauth',
    name: 'Unauth Home',
    component: UnauthLayout,
    children: [
      {
        path: '/creator-dashboard-after-paired',
        name: 'Creator Dashboard - Facebook Paired',
        component: () => import('@/views/creator/facebook/AfterPaired'),
      },
      {
        path: '/creator-dashboard-youtube-after-paired',
        name: 'Creator Dashboard - Youtube Paired',
        component: () => import('@/views/creator/youtube/AfterPaired'),
      },
    ],
  },
]
