export default [
  {
    path: 'operations-center',
    name: 'Instagram Operations Center',
    component: () => import('@/views/operations/OperationsCenterInstagram.vue'),
    meta: { requiresAcl: ['PAGE_OPS_INSTAGRAM_REPORT'] },
  },
  {
    path: 'account-report',
    name: 'Instagram Account Report',
    component: () =>
      import('@/views/operations/instagram-account-report/AccountReport.vue'),
    meta: { requiresAcl: ['PAGE_OPS_INSTAGRAM_ACCOUNT_REPORT'] },
  },
]
