export default [
  {
    path: 'operations-center',
    name: 'Tiktok Operations Center',
    component: () => import('@/views/operations/OperationsCenterTiktok.vue'),
    meta: { requiresAcl: ['PAGE_OPS_TIKTOK_REPORT'] },
  },
  {
    path: 'account-report',
    name: 'Tiktok Account Report',
    component: () =>
      import('@/views/operations/tiktok-account-report/AccountReport.vue'),
    meta: { requiresAcl: ['PAGE_OPS_TIKTOK_ACCOUNT_REPORT'] },
  },
]
