const state = {
  channelName: null,
  channelLink: null,
}

const mutations = {
  INVALIDATE_CHANNEL_STATE(state) {
    state.channelName = null
    state.channelLink = null
  },
  SET_CHANNEL(state, payload) {
    Object.keys(payload).forEach((key) => {
      state[key] = payload[key]
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
