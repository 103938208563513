import { h, resolveComponent } from 'vue'

import DefaultLayout from '@/layouts/DefaultLayout'
import unauthRoutes from './unauth/unauth-routes'
import demoRoutes from './demo/demo-routes'

import redirections from './redirections'
import adminRoutes from './admin/admin-routes'

import creatorRoutes from './creators/creator-routes'
import operationsRoutes from './operations/index'

export default [
  {
    path: '/',
    name: 'Home',
    redirect: '/operations/youtube/operations-center',
    meta: { requiresAuth: true },
    component: DefaultLayout,
    children: [
      ...redirections,
      {
        // this is just a placeholder page to use same route rerendering for admin mode
        path: 'empty',
        name: 'Empty',
        component: () => import('@/views/pages/Empty.vue'),
      },
      {
        path: '/operations',
        name: 'Operations',
        redirect: '/operations/youtube/operations-center',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [...operationsRoutes],
      },
      {
        path: '/creators',
        name: 'Creators',
        redirect: '/creators/home',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [...creatorRoutes],
      },
      {
        path: '/demo',
        name: 'Demo',
        redirect: '/demo/main',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        meta: { requiresAcl: ['DEV_MODE'] },
        children: [...demoRoutes],
      },
      {
        path: '/admin',
        name: 'Admin',
        redirect: '/admin/user',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        // meta: {
        //   requiresAuth: true,
        //   requiresAcl: ['PAGE_CLIENT_DASHBOARD'],
        // },
        children: [...adminRoutes],
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [...unauthRoutes],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Page404',
    component: () => import('@/views/pages/Page404'),
  },
]
